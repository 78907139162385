<template>
  <v-row
    no-gutters
    :class="{
      invalid: !valid,
      valid: valid,
      'text-body-2': true,
      'font-weight-light': true,
      'py-2': true
    }"
  >
    <v-col cols="12" v-if="!valid && isDuplicated">
      <v-banner color="red--text font-weight-bold text-center" single-line>
        Record already exists in the system, please verify and update if needed
      </v-banner>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4" v-if="!isRelease">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': tagNumberHasError
                }"
              >
                Tag Number
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ tagNumber }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4" v-else>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': speciesHasError
                }"
              >
                Species
              </v-list-item-title>
              <v-list-item-subtitle
                v-for="item in speciesList"
                v-bind:key="item"
              >
                {{ item }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="!speciesList.length">
                Not Specified
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': dateHasError }"
              >
                {{ isRelease ? 'Release' : 'Tag' }} Date
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ tagDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title> Entered Date </v-list-item-title>
              <v-list-item-subtitle>
                {{ enteredDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': oceanHasError }"
              >
                Ocean
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ ocean }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="8">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': coordinatesHasError
                }"
              >
                Coordinates
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ coords }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="4">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title
            :class="{
              'red--text font-weight-bold': locationHasError
            }"
          >
            Location
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ location }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" sm="4" v-if="!isRelease">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title
            :class="{ 'red--text font-weight-bold': fishLengthHasError }"
          >
            Fish Length
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ fishLength }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" sm="4" v-if="!isRelease">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title
            :class="{ 'red--text font-weight-bold': fishWeightHasError }"
          >
            Fish Weight
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ fishWeight }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" lg="6" v-if="!isRelease">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': speciesHasError
                }"
              >
                Species
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ specieName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': conditionHasError
                }"
              >
                Fish Condition
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ fishCondition }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': fightTimeHasError
                }"
              >
                Fight Time
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ fightTime }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6" v-else>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title> Lines In </v-list-item-title>
              <v-list-item-subtitle> {{ relLines }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title> Lines Out </v-list-item-title>
              <v-list-item-subtitle> {{ relLines0 }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': linesHasError }"
              >
                Total Lines
              </v-list-item-title>
              <v-list-item-subtitle> {{ totalLines }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': baitHasError }"
              >
                Bait
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ bait }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': hookHasError }"
              >
                Hook Type
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ hook }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4" v-if="!isRelease">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': gearHasError }"
              >
                Gear Type
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ gear }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': boatHasError }"
              >
                Boat
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ boatName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title> Shark Encounter </v-list-item-title>
              <v-list-item-subtitle>
                {{ sharkEncounter }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{ 'red--text font-weight-bold': anglerHasError }"
              >
                Angler
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ angler }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': captainHasError
                }"
              >
                Captain
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ captain }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': firstMateHasError
                }"
              >
                First Mate
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ firstMate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="4">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                :class="{
                  'red--text font-weight-bold': secondMateHasError
                }"
              >
                Second Mate
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ secondMate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="4" v-if="!isRelease">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title
            :class="{ 'red--text font-weight-bold': tournamentHasError }"
          >
            Tournament
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ tournament }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" lg="6">
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-list-item-title> Notes </v-list-item-title>
          <v-list-item-subtitle>
            {{ notes }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-btn
      class="ribbon"
      icon
      depressed
      color="red"
      @click.stop="$emit('remove')"
    >
      <v-icon>mdi-trash-can</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import { fixTimezone } from '../../helpers/format';
import { validCoordinates } from '../../helpers/map';

export default {
  name: 'RecordPreview',
  props: {
    record: {
      type: Object,
      required: true
    },
    recordType: {
      type: String,
      default: 'tags'
    }
  },
  computed: {
    valid() {
      const { ready } = this.record || {};
      return Boolean(ready);
    },
    coords() {
      const areValid = validCoordinates(this.record.coordinates);

      if (!areValid) {
        return 'Invalid Coordinates';
      }

      const { latitude, longitude } = this.record.coordinates || {};

      const latitudeStr = `${latitude['degrees']}° ${latitude['minutes']}' ${latitude['hemisphere']}`;
      const longitudeStr = `${longitude['degrees']}° ${longitude['minutes']}' ${longitude['hemisphere']}`;

      return `${latitudeStr} / ${longitudeStr}`;
    },
    boatName() {
      const { boat, boatName } = this.record || {};
      return boat?.name || boatName || 'Not Specified';
    },
    fightTime() {
      const { hours, minutes } = this.record?.capture?.fish?.fight || {};

      if (hours) {
        return [hours, minutes]
          .filter(Boolean)
          .map((value) => (value < 10 ? '0' + value : value))
          .join(':')
          .concat('h');
      }

      if (!minutes) {
        return 'Not specified';
      }

      return (minutes < 10 ? '0' + minutes : minutes) + 'min';
    },
    fishLength() {
      return this.getFishStat(this.record.capture.fish.length);
    },
    fishWeight() {
      return this.getFishStat(this.record.capture.fish.weight);
    },
    location() {
      const { coordinates, locality } = this.record || {};
      return coordinates?.description || locality || 'Not Specified';
    },
    tagDate() {
      const { createdDate } = this.record || {};
      return this.formatDate(createdDate);
    },
    enteredDate() {
      const { enteredDate } = this.record || {};
      return this.formatDate(enteredDate);
    },
    specieName() {
      const { specie } = this.record?.capture?.fish || {};
      return specie?.name || 'Not Specified';
    },
    speciesList() {
      const { fish } = this.record?.capture || {};
      return fish?.map(({ name, qty }) => `${name} (${qty})`) || [];
    },
    bait() {
      const { bait } = this.record?.capture || {};
      return bait?.name || 'Not Specified';
    },
    ocean() {
      const { ocean } = this.record?.capture || {};
      return ocean || 'Not Specified';
    },
    fishCondition() {
      const { condition } = this.record?.capture?.fish || {};
      return condition?.name || 'Not Specified';
    },
    tagNumber() {
      const { number } = this.record || {};
      return number || 'Not Specified';
    },
    sharkEncounter() {
      const { sharkEncounter } = this.record || {};
      return sharkEncounter ? 'Yes' : 'No';
    },
    gear() {
      const { gear } = this.record?.capture || {};
      return gear?.name || 'Not Specified';
    },
    hook() {
      const { hook } = this.record?.capture || {};
      return hook?.name || 'Not Specified';
    },
    tournament() {
      const { tournament } = this.record?.capture || {};
      return tournament?.name || 'Not Specified';
    },
    angler() {
      const { angler } = this.record || {};
      return this.fullName(angler);
    },
    captain() {
      const { captain } = this.record || {};
      return this.fullName(captain);
    },
    firstMate() {
      const { firstMate } = this.record || {};
      return this.fullName(firstMate);
    },
    secondMate() {
      const { secondMate } = this.record || {};
      return this.fullName(secondMate);
    },
    notes() {
      const { notes } = this.record?.capture || {};
      return notes || 'Not Specified';
    },
    isRelease() {
      return this.recordType === 'releases';
    },
    relLines() {
      const { relLines } = this.record || {};
      return relLines || 'Not Specified';
    },
    relLines0() {
      const { relLines0 } = this.record || {};
      return relLines0 || 'Not Specified';
    },
    totalLines() {
      const { relNumLi } = this.record || {};
      return relNumLi || 'Not Specified';
    },
    tagNumberHasError() {
      return this.getTagError('tagnumber');
    },
    speciesHasError() {
      return this.getTagError('species');
    },
    dateHasError() {
      return this.getTagError('date');
    },
    oceanHasError() {
      return this.getTagError('ocean');
    },
    coordinatesHasError() {
      return this.getTagError('coordinates');
    },
    locationHasError() {
      return this.getTagError('location');
    },
    fishLengthHasError() {
      return this.getTagError('fishlength');
    },
    fishWeightHasError() {
      return this.getTagError('fishweight');
    },
    conditionHasError() {
      return this.getTagError('condition');
    },
    fightTimeHasError() {
      return this.getTagError('fighttime');
    },
    baitHasError() {
      return this.getTagError('bait');
    },
    hookHasError() {
      return this.getTagError('hook');
    },
    gearHasError() {
      return this.getTagError('gear');
    },
    boatHasError() {
      return this.getTagError('boat');
    },
    anglerHasError() {
      return this.getTagError('angler');
    },
    captainHasError() {
      return this.getTagError('captain');
    },
    firstMateHasError() {
      return this.getTagError('firstmate');
    },
    secondMateHasError() {
      return this.getTagError('secondmate');
    },
    tournamentHasError() {
      return this.getTagError('tournament');
    },
    linesHasError() {
      return this.getTagError('lines');
    },
    isDuplicated() {
      return this.getTagError('duplicated');
    }
  },
  methods: {
    getTagError(tag) {
      const { errors } = this.record || {};

      if (!errors?.length) {
        return false;
      }

      return errors.some((error) => error.includes(tag));
    },
    fullName(user) {
      const { firstName, lastName, id } = user || {};

      if (!id) {
        return 'Not specified';
      }

      return (
        [firstName, lastName].filter(Boolean).join(' ').toUpperCase() || id
      );
    },

    formatDate(dateValue) {
      if (!dateValue) {
        return 'Not specified';
      }

      const date = fixTimezone(dateValue);
      return date ? date.format('MM/DD/YYYY') : 'Invalid Date';
    },

    getFishStat(data) {
      const { value, unit, determination, type } = data || {};

      if (!value && Number(value) !== 0) {
        return 'Not specified';
      }

      const measure = [value, unit].filter(Boolean).join(' ');
      return (
        [measure, type, determination].filter(Boolean).join(', ') || 'Unknown'
      );
    }
  }
};
</script>

<style scoped>
.bg-primary {
  background-color: #1976d2 !important;
  display: flex;
}
.bg-secondary {
  background-color: #424242 !important;
  display: flex;
}

.invalid {
  border-left: 5px solid red;
  border-right: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-radius: 4px;
}

.valid {
  border-left: 5px solid rgb(30, 136, 229);
  border-right: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-radius: 4px;
}

.ribbon {
  position: absolute;
  top: 1.5em;
  right: 2em;
}
</style>
